module.exports = new Promise(resolve => {
      const src = window.AppConfig.KNOWLEDGE_UI_URL + "/remoteEntry.js"
      const script = document.createElement('script')
      script.src = src
       
       script.onload = () => {
        const proxy = {
          get: (request) => window.codat_knowledge_ui.get(request),
          init: (arg) => {
            try {
              return window.codat_knowledge_ui.init(arg)
            } catch(e) {
              console.error(e)
            }
          }
        }
        resolve(proxy)
      }
      script.onerror = () => {
        console.error("Failed to load " + src)
        resolve()
      }
      document.head.appendChild(script);
    });